import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import NotFoundSection from "../sections/not-found-section";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <NotFoundSection />
  </Layout>
);

export default NotFoundPage;
