import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Container } from "@material-ui/core";
import NotFoundImage from "../images/404.jpg";

const styles = theme => ({
  root: {
    width: "100vw",
    height: "auto",
    flexGrow: 1,
    spacing: 0,
    justify: "space-around",
    textAlign: "center",
    alignItems: "center",
  },
  container: {
    height: "100vh",
    width: "100%",
    margin: "0 auto",
    backgroundImage: `url(${NotFoundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundAttachment: "scroll",
  },
});
const NotFound = props => {
  return (
    <section id="NotFound" className={props.classes.root}>
      <div className={props.classes.container}></div>
    </section>
  );
};

export default withStyles(styles)(NotFound);
